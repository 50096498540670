import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import SecurityIcon from "@mui/icons-material/Security";
import { Style } from "../../config";
import { useTheme } from "@mui/material";

function ActionButton({ url, Icon }) {
  const location = useLocation();
  const theme = useTheme();
  const color = Style.Sidebar.Color;
  const backgroundColor = location.pathname.startsWith(url)
    ? theme.palette.primary.main :
    Style.Sidebar.Background;

  return (
    <IconButton sx={{ color, backgroundColor }} component={Link} to={url}>
      <Icon />
    </IconButton>
  );
}

export default function MobileSidebar() {
  return (
    <AppBar position="fixed" color="blackBackground" sx={{ top: "auto", bottom: 0, flexGrow: 1 }}>
      <Toolbar sx={{ flexGrow: 1, justifyContent: "space-between" }}>
        <ActionButton Icon={HomeIcon} url="/homepage" />
        <ActionButton Icon={ReceiptLongIcon} url="/transactions" />
        <ActionButton Icon={AddIcon} url="/new-transaction" />
        <ActionButton Icon={SecurityIcon} url="/safety-advice" />
        <ActionButton Icon={AccountCircleOutlinedIcon} url="/user-settings" />
        <ActionButton Icon={LogoutIcon} url="/logout" />
      </Toolbar>
    </AppBar>
  );
}

