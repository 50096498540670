import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { FormControlLabel, Checkbox, FormGroup} from "@mui/material";
import Typography from "@mui/material/Typography";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import AddIcon from "@mui/icons-material/Add";
import InputTextField from "../common/form/InputTextField";
import { useValidation } from "../../hooks/useValidation";
import { useLoadingContext, withLoading } from "../../contexts/LoadingContext";
import Transaction from "../../shared/model/transaction";
import VerticalContentGrid from "../common/VerticalContentGrid";
import SelectOption from "../common/SelectOption";
import { useApiErrorContext } from "../../contexts/ApiErrorContext";
import { CreateTransactionContext } from "../../contexts/CreateTransactionContext";

const roleOptions = [
  { key: Transaction.UserRole.Seller, label: "Myyjä" },
  { key: Transaction.UserRole.Buyer, label: "Ostaja" },
];

const labels = {
  role: "Valitse oletko myymässä vai ostamassa",
  link: "Linkki myynti-ilmoitukseen",
  instructions: "Valitse, oletko myymässä vai ostamassa, ja kopioi myynti-ilmoituksen osoite selaimesi osoiteriviltä tapahtuman tekemistä varten.",
  terms: "Ymmärrän, että turvamaksut.fi ei ole kaupan osapuoli ja vastaa ainoastaan rahaliikenteen hoitamisesta.",
};

export default function CreateTransaction() {
  const [selectedRole, setSelectedRole] = useState(null);
  const [roleValidation, setRoleValidation] = useState(null);
  const [addLink, setAddLink] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const navigate = useNavigate();
  const apiErrorContext = useApiErrorContext();
  const createTransactionContext = useContext(CreateTransactionContext);
  const loadingContext = useLoadingContext();
  const loading = loadingContext.isLoading.length > 0;

  const validateRole = () => {
    const error = !selectedRole ? "Pakollinen" : null;
    setRoleValidation(error);
    return error;
  };

  const handleRoleSelect = (option) => {
    setSelectedRole(option);
    setRoleValidation(null);
  };

  const handleCheckboxChange = (event) => {
    setIsTermsChecked(event.target.checked);
  };

  const validation = {
    role: validateRole,
    link: useValidation(Transaction.validation.link),
  };

  const submit = async (event) => withLoading(loadingContext, async () => {
    event.preventDefault();

    const errors = Object.entries(validation)
      .map(([key, validation]) => key === "role" ? ([key, validation()]) : ([key, validation.validate()]))
      .filter(([key, error]) => error !== null);

    if (errors.length > 0) {
      const error = errors.map(([key, error]) => `- ${labels[key] ?? "N/A"}: ${error}`).join("\n");
      apiErrorContext.addError(`Tiedoissa on virheitä:\n\n${error}`);
      return;
    }

    const role = selectedRole.key;
    const link = validation.link.inputRef.current.value;
    await createTransactionContext.create(role, link);
    navigate("/new-transaction/create-manually");
  });

  useEffect(() => {
    createTransactionContext.reset();
  }, []);

  return (
    <form onSubmit={submit} style={{ height: "fit-content" }}>
      <VerticalContentGrid>
        <Grid item container justifyContent="center" alignItems="center" gap={2} paddingBottom={4}>
          <AddSharpIcon fontSize="large" />
          <Typography variant="h4">Uusi kauppa</Typography>
        </Grid>
        <Grid item paddingBottom={2}>
          <Typography variant="body2">
            {labels.instructions}
          </Typography>
        </Grid>
        <Grid item width="100%">
          <SelectOption
            options={roleOptions}
            selectedOption={selectedRole}
            setSelectedOption={(option) => handleRoleSelect(option)}
            error={roleValidation}
            fullWidth
            //label={labels.role}
          />
        </Grid>
        <Grid item width="100%" paddingBottom={2}>
          {/*{addLink ? (*/}
          <InputTextField
            required
            fullWidth 
            label={labels.link}
            {...validation.link}
          />
          {/*{ ) : 
            <Button
              startIcon={<AddIcon />}
              onClick={() => setAddLink(!addLink)}
            >
          Lisää linkki myynti-ilmoitukseen
            </Button>
          )}(*/}
        </Grid>
        <Grid>
          <FormControlLabel required control={
            <Checkbox size="small" checked={isTermsChecked} onChange={handleCheckboxChange}/>} 
          label={
            <Typography variant="body2">
              {labels.terms} *
            </Typography>
          }
          />
        </Grid>
        <Grid item width="100%">
          <Button fullWidth type="submit" variant="contained" disabled={loading || !isTermsChecked}>
            {loading ? "Ladataan" : "Jatka"}
          </Button>
        </Grid>
      </VerticalContentGrid>
    </form>
  );
}

