import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SecurityIcon from "@mui/icons-material/Security";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useUserContext } from "../../contexts/UserContext";
import { 
  Card, 
  CardActionArea, 
  CardContent, 
  CardMedia, 
  useTheme, 
  useMediaQuery } 
  from "@mui/material";
import VerticalContentGrid from "../common/VerticalContentGrid";

export default function SafetyAdvice() {
  const { user } = useUserContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const safetyAdvice = [
    "Kun ostaja on maksanut tuotteen, niin ilmoitamme asiasta sähköpostilla. Tuote näkyy myös maksettuna palvelussa.",
    "Ostajan ei tule lähetellä itse mitään maksukuitteja, tarkista aina palvelusta onko tuote maksettu vai ei.",
    "Älä lähetä tuotetta ennen kuin tuote näkyy maksettuna palvelussa.",
    "Emme koskaan kysele korttitietojasi, maksut tilitetään rekisteröinnin yhteydessä ilmoitettuun tilinumeroon.",
    "Emme myöskään lähetä sinulle tekstiviestejä, joissa ohjataan johonkin kolmannen osapuolen palveluun.",
    "Kaupan molempien osapuolten tulee olla rekisteröitynyt palveluun, jotta maksaminen on ylipäätään mahdollista."
  ];

  return (
    <VerticalContentGrid rowSpacing={4}>
      <Grid container item direction="column">
        <Grid item mb={2}>
          <Typography variant="h4">
            Turvallisuusohjeet
          </Typography>
        </Grid>
        <Grid item>
          <List sx={{ width: "100%" }} component="ol">
            {safetyAdvice.map((value, key) => (
              <ListItem
                key={`advice-${key}`}
                sx={{ display: "list-item", listStyleType: "decimal" }}
                pl={2}
              >
                <ListItemText primary={`${value}`} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </VerticalContentGrid>
  );
}

