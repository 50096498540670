import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import InputTextField from "../common/form/InputTextField";
import ManageProductImages from "../common/ManageProductImages";
import { useValidation } from "../../hooks/useValidation";
import { useLoadingContext, withLoading } from "../../contexts/LoadingContext";
import VerticalContentGrid from "../common/VerticalContentGrid";
import { useApiErrorContext } from "../../contexts/ApiErrorContext";
import { CreateTransactionContext } from "../../contexts/CreateTransactionContext";
import Transaction from "../../shared/model/transaction";

export default function PicturesAndDescription() {
  const createTransactionContext = useContext(CreateTransactionContext);
  const transactionId = createTransactionContext.current.id;
  const [images, setImages] = useState(createTransactionContext.current.images);
  const navigate = useNavigate();
  const loadingContext = useLoadingContext();
  const apiErrorContext = useApiErrorContext();
  const descriptionValidation = useValidation(Transaction.validation.description);

  const loading = loadingContext.isLoading.length > 0;

  const handleContinue = async () => withLoading(loadingContext, async () => {
    /*const error = descriptionValidation.validate();
    if (error !== null) {
      apiErrorContext.addError(error);
      return;
    }*/

    createTransactionContext.modify({
      ...createTransactionContext.current,
      images,
      //description: descriptionValidation.inputRef.current.value,
    });
    navigate("/new-transaction/additional-information");
  });

  useEffect(() => {
    if (transactionId === null) {
      navigate("/new-transaction");
    }
  }, [transactionId, navigate]);

  return (
    <VerticalContentGrid>
      <Grid item container justifyContent="center" alignItems="center" gap={2}>
        <AddSharpIcon fontSize="large" />
        {/*<Typography variant="h4">Luo kauppa</Typography>*/}
        <Typography variant="h4">Lisää kuvia</Typography>
      </Grid>
      <Grid item>
        <ManageProductImages
          images={images}
          onImagesChange={setImages}
        />
      </Grid>
      {/*  <Grid item width="100%">
        <InputTextField
          label="Tuotteen kuvaus"
          fullWidth
          autoFocus
          multiline
          defaultValue={createTransactionContext.current.description}
          {...descriptionValidation} />
      </Grid>*/}
      <Grid item container justifyContent="space-between">
        <Button variant="outlined" component={Link} to="/new-transaction">
          Mene takaisin
        </Button>
        <Button variant="contained" onClick={handleContinue} disabled={loading}>
          Jatka
        </Button>
      </Grid>
    </VerticalContentGrid>
  );
}

