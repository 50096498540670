import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useUserContext } from "../../contexts/UserContext";
import { 
  Card, 
  CardActionArea, 
  CardContent, 
  CardMedia, 
  useTheme, 
  useMediaQuery } 
  from "@mui/material";
import VerticalContentGrid from "../common/VerticalContentGrid";

function ActionCard({ Icon, to, text }) {
  return (
    <Card sx={{ border: "1px solid #DDDDDD" }} elevation={2}>
      <CardActionArea component={Link} to={to}>
        <CardMedia sx={{ textAlign: "center" }}>
          <Icon sx={{ fontSize: 80, color: "primary.main", marginTop: 2 }} />
        </CardMedia>
        <CardContent sx={{ textAlign: "center" }}>
          <Typography variant="h6">
            {text}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default function Homepage() {
  const { user } = useUserContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));


  return (
    <VerticalContentGrid rowSpacing={4}>
      <Grid container item direction="column" justifyContent="center" alignItems="center">
        <Grid item>
          <Typography variant="body1" textAlign="center">
            Tervetuloa, {user?.name ?? ""}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" textAlign="center">
            Mitä tänään tehtäisiin?
          </Typography>
        </Grid>
      </Grid>

      <Grid container item spacing={2} justifyContent="center" alignItems="center">
        <Grid item width="200px" height="200px">
          <ActionCard Icon={AddIcon} to="/new-transaction" text="Uusi kauppa" />
        </Grid>
        <Grid item width="200px" height="200px">
          <ActionCard Icon={ReceiptLongIcon} to="/transactions" text="Kaupat" />
        </Grid>
      </Grid>
      <Grid container item alignItems="center" justifyContent="center" direction="row">
        <Grid item>
          <ErrorOutlineIcon style={{ paddingRight: "10px" }} />
        </Grid>
        <Grid item width={!isMobile ? "400px" : "auto"}>
          <Typography variant="body2" textAlign={isMobile ? "center" : "inherit"}>
            Palvelu toimii tällä hetkellä seuraavien pankkien kanssa: OP, Nordea, Danske Bank, S-Pankki.
          </Typography>
        </Grid>
      </Grid>
    </VerticalContentGrid>
  );
}

