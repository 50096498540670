import Joi from "joi";
import validationConsts from "../validationConsts.js";
const {
  TX_ITEM_DESC_MAX_LEN,
  TX_ITEM_NAME_MAX_LEN,
  TX_ITEM_MIN_PRICE,
  TX_ITEM_MAX_PRICE,
  USER_EMAIL_MAX_LEN,
  URL_MAX_LEN,
} = validationConsts;

const Status = {
  AwaitingApproval: "odottaa hyväksyntää",
  AwaitingRegistration: "odottaa myyjän rekisteröitymistä",
  AwaitingPayment: "odottaa maksua",
  AwaitingPaymentConfirmation: "maksu odottaa pankin vahvistusta",
  Paid: "maksettu",
  Delivered: "toimitettu",
  ReadyForPickup: "noudettavissa",
  PickedUp: "noudettu",
  Received: "vahvistettu",
  Cancelled: "peruttu",
  Problem: "ongelma",
  ProblemResolved: "ongelma ratkaistu",
};

const UserRole = {
  Buyer: "ostaja",
  Seller: "myyjä",
};

const StatusChange = {
  Deliver: {
    name: "lähetä",
    from: Status.Paid,
    to: Status.Delivered,
    requiredRole: [UserRole.Seller],
  },
  ReceiveDelivered: {
    name: "vastaanota",
    from: Status.Delivered,
    to: Status.Received,
    requiredRole: [UserRole.Buyer],
  },
  ReceiveReadyForPickup: {
    name: "vastaanota",
    from: Status.ReadyForPickup,
    to: Status.Received,
    requiredRole: [UserRole.Buyer],
  },
  PickUpReadyForPickup: {
    name: "vastaanota",
    from: Status.PickedUp,
    to: Status.Received,
    requiredRole: [UserRole.Buyer],
  },
  ProblemPickedUp: {
    name: "ongelma",
    from: Status.PickedUp,
    to: Status.Problem,
    requiredRole: [UserRole.Buyer],
  },
};

const validation = {
  name: Joi.string().max(TX_ITEM_NAME_MAX_LEN).messages({
    "string.empty": "Pakollinen",
    "string.max": "Maksimipituus 200 merkkiä",
  }),
  description: Joi.string()
    .allow("")
    .max(TX_ITEM_DESC_MAX_LEN)
    .messages({ "string.max": "Kuvauksen maksimipituus on 2000 merkkiä" }),
  price: Joi.number().min(TX_ITEM_MIN_PRICE).max(TX_ITEM_MAX_PRICE).messages({
    "number.base": "Virheellinen numero",
    "number.min": "Minimiarvo 5€",
    "number.max": "Maksimiarvo 500000€",
  }),
  fee: Joi.number().messages({
    "number.base": "Virheellinen numero",
  }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .max(USER_EMAIL_MAX_LEN)
    .messages({
      "string.empty": "Pakollinen",
      "string.email": "Virheellinen sähköpostiosoite",
      "string.max": "Maksimipituus 200 merkkiä",
    }),
  role: Joi.string()
    .valid(...Object.values(UserRole))
    .messages({
      "string.empty": "Pakollinen",
      "any.only": `Sallitut arvot: ${Object.values(UserRole).join(", ")}`,
    }),
  status: Joi.string()
    .valid(...Object.values(Status))
    .messages({
      "string.empty": "Pakollinen",
      "any.only": `Sallitut arvot: ${Object.values(Status).join(", ")}`,
    }),
  link: Joi.string().uri().max(URL_MAX_LEN).messages({
    "string.empty": "Pakollinen",
    "string.max": "Maksimipituus 500 merkkiä",
    "string.uri": "Virheellinen URL",
  }),
};

export default {
  Status,
  UserRole,
  StatusChange,
  validation,
};
