import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import UserProvider from "./contexts/UserContext";
import LoadingProvider from "./contexts/LoadingContext";
import ApiErrorProvider from "./contexts/ApiErrorContext";
import ConfirmationProvider from "./contexts/ConfirmationContext";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import { TokenProvider } from "./hooks/useToken";
import { CreateTransactionProvider } from "./contexts/CreateTransactionContext";
import ImagePopupContextProvider from "./contexts/ImagePopupContext";
import TrackingIdContextProvider from "./contexts/TrackingIdPopupContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <TokenProvider>
    <ThemeProvider theme={theme}>
      
        <ApiErrorProvider>
          <ConfirmationProvider>
            <CreateTransactionProvider>
              <UserProvider>
                <LoadingProvider>
                  <TrackingIdContextProvider>
                    <ImagePopupContextProvider>
                      <BrowserRouter>
                        <App />
                      </BrowserRouter>
                    </ImagePopupContextProvider>
                  </TrackingIdContextProvider>
                </LoadingProvider>
              </UserProvider>
            </CreateTransactionProvider>
          </ConfirmationProvider>
        </ApiErrorProvider>
      
    </ThemeProvider>
  </TokenProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
